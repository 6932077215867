import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Twilight = () => {
  const data = useStaticQuery(graphql`
    query {
      twilight: file(relativePath: { eq: "Twilight.png" }) {
        childImageSharp {
          fixed(width: 128, height:128) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img fixed={data.twilight.childImageSharp.fixed} />
  )
}

export default Twilight